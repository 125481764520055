import * as React from "react"
import { graphql } from "gatsby"
import Layout from "../components/layout"
import Seo from "../components/seo"

import "../styles/post.css"

const BlogPost = ({ data }) => {
	const post = data.markdownRemark
  React.useEffect(() => {
    document.body.classList.add('blog')
    return () => document.body.classList.remove('blog')
  }, [])
	return (
		<Layout>
			<article className="PageBlog Post">
				<header className="Header">
					<h1>{post.frontmatter.title}</h1>
					<div children={post.frontmatter.date} />
				</header>
				<div
					className="PostContent"
					dangerouslySetInnerHTML={{ __html: post.html }}
				/>
			</article>
		</Layout>
	)
}

export const Head = ({ data }) => {
	const post = data.markdownRemark
	return <Seo title={post.frontmatter.title} />
}

export const query = graphql`
	query ($slug: String!) {
		markdownRemark(fields: { slug: { eq: $slug } }) {
			html
			frontmatter {
				title
				date(formatString: "MMMM DD, YYYY")
			}
		}
	}
`

export default BlogPost
